import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TextGroup from '../components/TextGroup';

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <div className="BlueGradient">
      <div className="--headerClear"></div>
      <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt ">
        <h2><span>MxManager</span> is the hub for Mx Modules</h2>
        <p>Tap into real-time information on your shop floor and enjoy the benefits of increased production. </p>
      </section>
    </div>
    <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>Product Overview</h5>
        <p className="DarkP">Ncell Nesting Solutions offer a cohesive production environment that reduces production time, improves material utilization and streamlines processes. From stand-alone lasers to multi-laser FMS systems, AccuFAB increases the reliability and efficiency of your laser cutting systems. Accufab is more than a dynamic nesting system. Getting the right part at the right time is key to your shops profitability. Accufab ensures you have what you need when you need it the most. Feed production data in real time back to your ERP system for accurate part costing</p>
      </article>
    </section>
    <div className="--featImg" id="featMX"></div>
    <section className="--centerMargin --maxwidthL --grid6040 --edgePadding --sectionPace --alignCenter">
      <article>
        <h2 className="DarkH">Information where you want it</h2>
        <p className="DarkP">AccuFAB puts the right information in the right place to guarantee fast and easy part processing. Whether etched directly on a part, printed or displayed on shop floor monitors, AccuFAB helps shop floor personnel process parts quickly and accurately.</p>
      </article>
      <div id="stackedParts" className="--backgroundContain"></div>
    </section>
    <section className="--centerMargin --maxwidthL --grid">
    <div className="--centerMargin" id="productChip">
          <Link to="/mxmrp"> 
            <div id="ProductChip">
              <h4>MxMRP</h4>
              <hr></hr>
              <p>Real-Time Monitoring</p>
            </div>
            </Link>
          </div>
      <article  id="--MxOverview" className="--centerTxt --maxwidthM">
        <h2 className="DarkH">Downstream control with Mx Station</h2>
        <p className="DarkP">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited. </p>
      </article>
    </section>
    <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid5050">
    <article className="--maxwidthS">
      <h5>Downstream control with Mx Station</h5>
      <p className="DarkP">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited. </p>
    </article>
    <article className="--maxwidthS">
      <h5>Downstream control with Mx Station</h5>
      <p className="DarkP">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited. </p>
    </article>
    </section>
    <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid5050">
      <article className="--maxwidthS">
        <div className="--paragraphImg" id="paperShuffleImg"></div>
        <h5>Eliminate unproductive paper shuffling.</h5>
        <p className="DarkP">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited. </p>
      </article>
      <article className="--maxwidthS">
        <div className="--paragraphImg" id="finishedJobsImg"></div>
        <h5>All of your finished jobs in one place</h5>
        <p className="DarkP">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited. </p>
      </article>
    </section>
    <section className="--sectionPace --edgePadding --maxwidthM --centerMargin">
      <h2 className="DarkH --centerTxt">Downstream control anywhere you are</h2>
      <p className="DarkP --centerTxt">Rather than using more labor to sort parts faster, personnel have pop-up visuals that display the information needed to process the jobs that you need expedited. </p>
      <div className="--grid5050 --alignCenter">
        <section className="no_bullet">
        <img alt="#" src={require('../images/_icons/Clock_Icon.png')}/>
        <p className="DarkP">Up-to-the-minute info</p>
        </section>
        <section className="no_bullet">
        <img alt="#" src={require('../images/_icons/Wifi_icon.png')}/>
        <p className="DarkP">Full Remote access</p>
        </section>
      </div>
    </section>
    <section className="--sectionPace --centerTxt">
      <h2 className="DarkH">Expand MxStation with additional modules</h2>
      <p className="DarkP">Customize and control your suite of Mx products based on your shop needs. </p>
      <div className="--headerClear"></div>
      <div className="MXproducts" id="MxProductPage">
        <Link to="/mx"><span><h4>MxManager</h4></span></Link>
          <Link to="/mxmrp"> 
            <div id="ProductChip">
              <h4>MxMRP</h4>
              <hr></hr>
              <p>Real-Time Monitoring</p>
            </div>
          </Link>
          <Link to="/mxpallet"> 
            <div id="ProductChip">
              <h4>MxPallet</h4>
              <hr></hr>
              <p>Expedite finished pallets</p>
            </div>
          </Link>
          <Link to="/mxticket"> 
            <div id="ProductChip">
              <h4>MxTicket</h4>
              <hr></hr>
              <p>Smarter Labels & Tickets</p>
            </div>
          </Link>
          <Link to="/mxstation"> 
            <div id="ProductChip">
              <h4>MxStation</h4>
              <hr></hr>
              <p>Process Queue Control</p>
            </div>
          </Link>
          <Link to="/mxreport"> 
            <div id="ProductChip">
              <h4>MxReport</h4>
              <hr></hr>
              <p>Pinpoint Documentation</p>
            </div>
          </Link>


        </div>
      </section>
      <section className="ERP Pattern">
        <TextGroup 
          color="DarkH"
          title="Ncell works with your existing ERP"
          text="Our products are built to work with a variety of popular shop floor data systems, along with working as a stand-alone."
        />
        <div>
          <img alt="#" src={require('../images/SAP.png')}/>
          <img alt="#" src={require('../images/Epicore.png')}/>
          <img alt="#" src={require('../images/Infor.png')}/>
          <img alt="#" src={require('../images/Oracle.png')}/>
        </div>
        <p className="Footnote">Contact us for full support list.</p>
      </section>
      <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid6040">
      <artcle>
        <h2 className="DarkH">Handle unplanned production</h2>
        <p className="DarkP">See your overview for the work day and keep tabs on your shop. Our intelligent alert system brightly indicates when a machine has encountered an error, helping you decrease down-time and stay on track. With up to 3 lasers supported per display, true transparency has never looked so good.</p>
      </artcle>
      <div className="no_bullet">
                <img alt="#" src={require('../images/_icons/Warning_Icon.png')}/>
                <p className="DarkP">Color coded laser status</p>
                <img alt="#" src={require('../images/_icons/Clock_Icon.png')}/>
                <p className="DarkP">Shift Management</p>
                <img alt="#" src={require('../images/_icons/Fast_Icon.png')}/>
                <p className="DarkP">Increased Awareness of Cell Effeciency</p>
                <img alt="#" src={require('../images/_icons/Notification_icon.png')}/>
                <p className="DarkP">Collect Event Notifications</p>

            </div>    
        </section>
        <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --alignTop --grid6040 ">
      <artcle>
        <h2 className="DarkH">Handle unplanned production</h2>
        <p className="DarkP">See your overview for the work day and keep tabs on your shop. Our intelligent alert system brightly indicates when a machine has encountered an error, helping you decrease down-time and stay on track. With up to 3 lasers supported per display, true transparency has never looked so good.</p>
      </artcle>
      <article>
      <h5>Included Options:</h5>
      <div className="no_bullet">
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Intelligent Slug Destruct</p>
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Automatic Micro-Tabbing</p>
                <img alt="#" src={require('../images/_icons/checkmark.svg')}/>
                <p className="DarkP">Smart Grain Restrictions</p>

            </div>    
            </article>
        </section>
      <section className="--maxwidthL --edgePadding --sectionPace --grid --centerMargin ">
        <article className="--maxwidthL --centerMargin --centerTxt">
          <h2 className="DarkH">It Makes Sense</h2>
          <p className="DarkP">Taking advantage of the high speed cutting features on your laser, AccuFAB maximizes laser throughput and guarantees part quality without the need to program manually. </p>
          <div className="--headerClear"></div>
        </article>
        <section className="--grid5050">
        <article>
        <div className="--paragraphImg" id="commonSenseImg"></div>
        <h5>CommonSense</h5>
        <p className="DarkP">More than just common line cutting, CommonSense determines when and if common line cutting is beneficial on a case by case basis and nests parts for optimal results.  Because it is fully automatic, CommonSense decreases your production costs without adding additional manpower or time. </p>
      </article>
      <article>
        <div className="--paragraphImg" id="kitSenseImg"></div>
        <h5>KitSense</h5>
        <p className="DarkP">Manage complex assemblies through your lasers with KitSense. By automatically grouping assembly parts into kits, KitSense guarantees all parts for an assembly are cut together, giving you more finished products in less time. Advanced tools like automatic back fill and flexible order quantities guarantee you get the assemblies you need and the material utilization you demand. 
</p>
      </article>
      </section>
      </section>
      <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">

        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
  </Layout>
)

export default SecondPage
